import React from 'react'
import styled from 'styled-components'

import { TEXT_STYLES } from 'constants/text_styles'
import { Icon } from '@junglescout/edna'

const Container = styled.div`
  ${TEXT_STYLES.H5Inactive};
  margin-top: 8px;
  margin-bottom: 36px;
`
const DisclaimerThumbs = styled.span`
  display: inline-flex;
  height: 16px;
  width: 28px;
  margin: 0 2px;
  position: relative;
  div {
    position: absolute;
    display: inline-flex;
    gap: 4px;
    top: 6px;
  }
`
const Disclaimer = () => (
  <Container>
    {
      'AI Assist Chat is in early beta and may produce inaccurate results. Your testing and '
    }
    <DisclaimerThumbs>
      <div>
        <Icon name="THUMBS_UP" height="12px" width="12px" />
        <Icon name="THUMBS_DOWN" height="12px" width="12px" />
      </div>
    </DisclaimerThumbs>
    {' feedback helps it get smarter every day.'}
  </Container>
)

export { Disclaimer }
