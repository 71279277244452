import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Icon } from '@junglescout/edna'
import { TEXT_STYLES } from 'constants/text_styles'
import { AnswerArticleType } from './types'

const Container = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.length}, minmax(100px, auto));
  justify-content: flex-start;
  margin-bottom: 8px;
`

const Item = styled.div`
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-radius: 2px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  &:hover {
    background-color: ${props => props.theme.colors.grey100};
  }
`
const Link = styled.label`
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  cursor: pointer;
  ${TEXT_STYLES.H5Black};
  text-decoration: none;
  margin-left: 4px;
  color: ${props => props.theme.colors.primary};
`

const AnswerArticles = ({ articles }) => {
  const filteredArticles = articles?.length ? [...articles.slice(0, 3)] : []

  return filteredArticles.length ? (
    <Container length={filteredArticles.length}>
      {filteredArticles.map((meta, i) => {
        const onClick = () => window.open(meta.source, '_blank')
        return (
          <Item key={String(i)} onClick={onClick}>
            <Icon name="LINK_EXTERNAL" width="12px" height="12px" />
            <Link>{meta.title}</Link>
          </Item>
        )
      })}
    </Container>
  ) : null
}

AnswerArticles.propTypes = {
  articles: PropTypes.arrayOf(AnswerArticleType)
}

AnswerArticles.defaultProps = {
  articles: []
}

export { AnswerArticles }
