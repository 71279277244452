import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import moment from 'moment'

import { Button, Icon, Text } from '@junglescout/edna'

import { reactivateMembership } from 'actions/account'
import { createBanner, removeBanner } from 'actions/banners'

const BannerWrapper = styled.div`
  padding: 0 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.colors.yellow100};
  min-height: 48px;
`
const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
`

const calculateDaysUntilCancel = periodEnd => {
  const oneDay = 1000 * 60 * 60 * 24
  const currentDate = new Date().getTime()
  const endDate = new Date(periodEnd).getTime()

  return Math.round((endDate - currentDate) / oneDay)
}

export const MembershipStatusBanners = () => {
  const { t } = useTranslation('account')

  const { colors } = useTheme()
  const dispatch = useDispatch()

  const {
    membershipAdmin,
    membershipStatus,
    payment_plan: paymentPlan,
    next_invoice_date: nextInvoiceDate
  } = useSelector(state => state.globalData.membershipInfo)

  const {
    reactivate: { isLoading }
  } = useSelector(state => state.account)

  const numberOfDaysUntilCancel = calculateDaysUntilCancel(nextInvoiceDate)

  const isWithinShowPeriod =
    paymentPlan?.toLowerCase() === 'monthly'
      ? numberOfDaysUntilCancel <= 7
      : numberOfDaysUntilCancel <= 30

  const renderReactivateBanner = useCallback(() => {
    return (
      <BannerWrapper>
        <MessageWrapper>
          <Icon
            name="TOOLTIP_WARNING"
            height="37px"
            width="42px"
            color={colors.yellow500}
          />
          <Text variant="bodyMd" fontWeight="600" margin="0 4px 0 0">
            {t(
              'account:reactivateMembershipBanner.title',
              'Your plan is set to cancel:'
            )}
          </Text>
          <div>
            {t(
              'account:reactivateMembershipBanner.message',
              'You have access until the end of this billing cycle ({{invoiceDate}}).',
              { invoiceDate: moment(nextInvoiceDate).format('MMMM D, YYYY') }
            )}
          </div>
        </MessageWrapper>
        {membershipAdmin && (
          <Button
            isLoading={isLoading}
            onClick={() => dispatch(reactivateMembership())}>
            {t('account:reactivateMembershipBanner.cta', 'Resume Membership')}
          </Button>
        )}
      </BannerWrapper>
    )
  }, [colors, dispatch, membershipAdmin, nextInvoiceDate, isLoading, t])

  const renderPaymentIssueBanner = useCallback(() => {
    return (
      <Trans t={t} i18nKey="account:paymentIssueBanner.message">
        There is a payment issue with your credit card. Please update your
        payment&nbsp;
        <Text
          variant="bodyMd"
          element="a"
          href="#/account/billing"
          color="inherit"
          underline>
          here
        </Text>
        &nbsp;to avoid disruption to your service.
      </Trans>
    )
  }, [t])

  useEffect(() => {
    if (membershipStatus === 0 && isWithinShowPeriod && nextInvoiceDate) {
      dispatch(removeBanner('payment-issue'))
      dispatch(
        createBanner({
          id: 'reactivate-membership',
          type: 'warning',
          isCustom: true,
          message: renderReactivateBanner()
        })
      )
    } else if (membershipStatus === 2) {
      dispatch(removeBanner('reactivate-membership'))
      dispatch(
        createBanner({
          id: 'payment-issue',
          type: 'warning',
          message: renderPaymentIssueBanner()
        })
      )
    }
  }, [
    dispatch,
    membershipStatus,
    nextInvoiceDate,
    isWithinShowPeriod,
    renderReactivateBanner,
    renderPaymentIssueBanner
  ])

  return null
}
