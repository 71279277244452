import React from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { getPathData } from 'services/segment'
import { getPathnameFromFullHashUrl } from 'helpers/url'
import { sendCtaClickEvent } from 'helpers/segment'

import { AIButton, AIButtonSize } from '@junglescout/edna'

export const ChatbotSlideoutTrigger = () => {
  const { t } = useTranslation('chatbot')
  const trackButtonClick = () => {
    const pageUrl = window.location.href
    const pathName = getPathnameFromFullHashUrl(pageUrl)
    const pathData = getPathData(pathName)
    const location = pathData?.name || ''

    sendCtaClickEvent({
      pageUrl,
      location,
      text: 'AI Assist Chat'
    })
  }

  return (
    <AIButton
      onClick={trackButtonClick}
      btnSize={isMobile ? AIButtonSize.XSMALL : AIButtonSize.SMALL}>
      {t('chatbot:common.cta', 'AI Assist Chat')}
    </AIButton>
  )
}
