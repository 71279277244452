import React from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'
import { Icon, Tooltip } from '@junglescout/edna'

import { TEXT_STYLES } from 'constants/text_styles'
import { BLOCKED_REQUEST_TYPES } from 'constants/chatbot/blockedRequests'
import { UPGRADE_LIMIT_MODAL } from 'constants/feature_limits'

import { NakedButton } from 'ui_elements/NakedButton/NakedButton'

import { useEndpointValidation } from 'hooks/chatRequests'

import { sendCtaClickEvent } from 'helpers/segment'

import { setGlobalModal, setGlobalModalMetadata } from 'actions/index'

const ButtonWrapper = styled(NakedButton)`
  width: auto;
  font-size: 13px;
  padding: 8px 12px;
  display: flex;
  gap: 10px;
  border-radius: 20px;
  border: 1px solid ${props => props.theme.colors.primary};
  &:hover {
    background: ${props => props.theme.colors.primary100};
  }
`

const Label = styled.label`
  ${TEXT_STYLES.H4Black};
  font-weight: 400 !important;
  line-height: 20px;
  cursor: inherit;
`

const Link = styled.a`
  cursor: pointer;
`

const AnswerSuggestion = ({ suggestion, onClick, iconName }) => {
  const { question, endpoint } = suggestion

  const dispatch = useDispatch()
  const { colors } = useTheme()

  const { blockedRequestType } = useEndpointValidation(endpoint)

  const sendSuggestion = () => {
    sendCtaClickEvent({
      text: 'AI Assist Chat - Suggestion Clicked',
      extraProps: {
        subtext: question,
        blockedReason: blockedRequestType
      }
    })

    onClick()
  }

  const onUpgrade = () => {
    dispatch(setGlobalModalMetadata({ initiator: 'AI Chatbot Requests' }))
    dispatch(setGlobalModal(UPGRADE_LIMIT_MODAL))
    sendCtaClickEvent({
      text: 'AI Assist Chat - Upgrade',
      type: 'link'
    })
  }

  let tooltip
  let iconExtra

  if (endpoint) {
    switch (blockedRequestType) {
      case BLOCKED_REQUEST_TYPES.REQUESTS_UPSELL:
        iconExtra = 'LOCK'
        tooltip = (
          <Trans i18nKey="chatbot:BlockedRequests.requestUpsell.tooltip">
            Available to Growth Accelerator and Brand Owner users. Ask tailored
            questions related to your business.{' '}
            <Link onClick={onUpgrade}>Upgrade Plan Tier</Link>
          </Trans>
        )
        break
      case BLOCKED_REQUEST_TYPES.SELLER_ACCOUNT_SYNCING:
        iconExtra = 'REFRESH'
        tooltip = (
          <Trans i18nKey="chatbot:BlockedRequests.sellerAccountSyncing.tooltip">
            Syncing your Amazon Seller Central Account - full data loads in up
            to 24 hours
          </Trans>
        )
        break
      default:
        break
    }
  }

  return (
    <Tooltip
      content={tooltip}
      disabled={!tooltip}
      side="right"
      size="large"
      sideOffset={4}>
      <ButtonWrapper onClick={sendSuggestion}>
        <Icon name={iconName} color={colors.primary} />
        <Label>{question}</Label>
        {iconExtra && (
          <Icon
            name={iconExtra}
            color={colors.primary}
            width="16px"
            height="16px"
          />
        )}
      </ButtonWrapper>
    </Tooltip>
  )
}

AnswerSuggestion.defaultProps = {
  iconName: 'AI_ASSIST'
}

AnswerSuggestion.propTypes = {
  suggestion: PropTypes.shape({
    question: PropTypes.string.isRequired,
    endpoint: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  iconName: PropTypes.string
}

export { AnswerSuggestion }
