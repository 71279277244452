import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import last from 'lodash/last'

import { useAnswerRequestSpec } from 'hooks/chatRequests'

import { QuestionPropTypes, AnswerPropTypes } from './types'
import { QuestionBubble } from './QuestionBubble'

import { AnswerArticles } from './AnswerArticles'
import { AnswerFeedback } from './AnswerFeedback'
import { AnswerMissingParams } from './AnswerMissingParams'
import { AnswerRequestBlocked } from './AnswerRequestBlocked'
import { ExportPDFButton } from './reports/ExportPDFButton'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ReportFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

const Answer = ({ question, answer, isStreaming, isLastOne, createAnswer }) => {
  const { clientRequestSpec, type } = answer
  const missingParams = clientRequestSpec?.missingParams || []
  const showRequestSpec = clientRequestSpec && type === 'request_spec'
  const currentParams = last(question.answers)?.clientRequestSpec?.params || {}

  const { isLoading, blockedRequestType, sendRequest } = useAnswerRequestSpec({
    clientRequestSpec,
    enabled: isLastOne && showRequestSpec,
    createAnswer: payload => createAnswer({ ...payload, id: question.id })
  })

  const disabled = !isLastOne || isStreaming || isLoading
  const isBlocked = showRequestSpec && blockedRequestType

  const showAnswer = answer.text && !isBlocked

  const isReport = answer.type === 'report'

  const renderFeedback = () => (
    <AnswerFeedback answer={answer} question={question} />
  )

  return (
    <Container>
      {showAnswer && <QuestionBubble text={answer.text} />}
      <AnswerArticles articles={answer.articles} />
      {!isReport && renderFeedback()}
      {showRequestSpec && (
        <>
          {blockedRequestType ? (
            <AnswerRequestBlocked blockedRequestType={blockedRequestType} />
          ) : (
            <AnswerMissingParams
              currentParams={currentParams}
              missingParams={missingParams}
              disabled={disabled}
              sendRequest={sendRequest}
            />
          )}
          {isLoading && !isStreaming && <QuestionBubble />}
        </>
      )}

      {isReport && (
        <ReportFooter>
          <ExportPDFButton answer={answer} />
          {renderFeedback()}
        </ReportFooter>
      )}
    </Container>
  )
}

Answer.propTypes = {
  question: QuestionPropTypes.isRequired,
  answer: AnswerPropTypes.isRequired,
  isStreaming: PropTypes.bool,
  isLastOne: PropTypes.bool,
  createAnswer: PropTypes.func.isRequired
}

Answer.defaultProps = {
  isStreaming: false,
  isLastOne: false
}

export { Answer }
