import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
  getBlockedRequestAnswers,
  BLOCKED_REQUEST_TYPES
} from 'constants/chatbot/blockedRequests'
import { AMAZON_MWS_PPC_SYNC_STEPS } from 'constants/account'
import { UPGRADE_LIMIT_MODAL } from 'constants/feature_limits'

import { showMwsSyncModal } from 'actions/account/mwsSync'
import { toggleChatbot } from 'actions/chatbot'
import { sendCtaClickEvent } from 'helpers/segment'
import { setGlobalModal, setGlobalModalMetadata } from 'actions/index'

import { QuestionBubble } from './QuestionBubble'
import { ChatButton } from '../ChatButton'

const AnswerRequestBlocked = ({ blockedRequestType }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('generic')

  const firstName = useSelector(state => state.account?.userInfo?.firstName)

  const answer = getBlockedRequestAnswers(blockedRequestType, firstName, t)

  const renderCTA = () => {
    const actions = {
      [BLOCKED_REQUEST_TYPES.NO_SELLER_ACCOUNT]: {
        text: t('generic:connectToAmazon', 'Connect to Amazon'),
        eventText: 'AI Assist Chat - Connect to Amazon',
        onClick: () => {
          dispatch(toggleChatbot())
          setTimeout(() => {
            dispatch(
              showMwsSyncModal(AMAZON_MWS_PPC_SYNC_STEPS.introduction, true)
            )
          }, 200)
        }
      },
      [BLOCKED_REQUEST_TYPES.REQUESTS_UPSELL]: {
        text: t('generic:UpgradePlan', 'Upgrade Plan'),
        eventText: 'AI Assist Chat - Upgrade',
        onClick: () => {
          dispatch(setGlobalModalMetadata({ initiator: 'AI Chatbot Requests' }))
          dispatch(setGlobalModal(UPGRADE_LIMIT_MODAL))
        }
      }
    }

    const action = actions[blockedRequestType]

    if (action) {
      const onClick = () => {
        action.onClick?.()
        if (action.eventText) sendCtaClickEvent({ text: action.eventText })
      }

      return <ChatButton onClick={onClick}>{action.text}</ChatButton>
    }

    return null
  }

  return (
    <>
      {answer && <QuestionBubble text={answer} />}
      {renderCTA()}
    </>
  )
}

AnswerRequestBlocked.propTypes = {
  blockedRequestType: PropTypes.string.isRequired
}

export { AnswerRequestBlocked }
