import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { isMobile } from 'react-device-detect'

import { TextInput, ClickableIcon } from '@junglescout/edna'
import { Disclaimer } from './Disclaimer'
import { useTranslation } from 'react-i18next'

const InputWrapper = styled.div`
  position: relative;
  input {
    font-size: ${isMobile ? '16px' : '12px'} !important;
    padding-right: 25px;
  }
`

const StyledClickableIcon = styled(ClickableIcon)`
  position: absolute;
  top: 4px;
  right: 8px;
`

const ChatBotFooter = ({ sendQuestion, disabled }) => {
  const inputRef = useRef(null)
  const { colors } = useTheme()
  const { t } = useTranslation('chatbot')

  const handleSubmit = () => {
    const question = inputRef?.current.value
    if (question) {
      sendQuestion({ question })
      inputRef.current.value = '' // reset input field
    }
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleSubmit()
  }

  return (
    <>
      <InputWrapper>
        <TextInput
          ref={inputRef}
          onKeyDown={handleKeyDown}
          placeholder={t(
            'chatbot:ChatbotFooter.input.placeholder',
            'Write your own message'
          )}
          type="text"
          disabled={disabled}
        />
        <StyledClickableIcon
          onClick={handleSubmit}
          name="SEND"
          color={colors.primary}
          ariaLabel={t(
            'chatbot:ChatbotFooter.input.btnAriaLabel',
            'Send message'
          )}
          padding="4px"
          disabled={disabled}
        />
      </InputWrapper>
      <Disclaimer />
    </>
  )
}

ChatBotFooter.propTypes = {
  sendQuestion: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

ChatBotFooter.defaultProps = {
  disabled: false
}

export { ChatBotFooter }
