import React, { useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Button, ButtonType, Popover } from '@junglescout/edna'
import { TEXT_STYLES } from 'constants/text_styles'

import { useClearChatHistory } from 'hooks/chat'

const ConfirmHeader = styled.div`
  ${TEXT_STYLES.H2Black};
  margin-bottom: 12px;
`
const ConfirmContent = styled.div`
  ${TEXT_STYLES.H5Light};
  margin-bottom: 20px;
`
const ConfirmButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ClearChatButton = () => {
  const [isOpen, setOpen] = useState(false)

  const { t } = useTranslation(['generic', 'chatbot'])
  const { clearChatHistory } = useClearChatHistory()

  const onDelete = () => {
    clearChatHistory()
    setOpen(false)
  }

  const renderContent = () => {
    return (
      <>
        <ConfirmHeader>
          {t('chatbot:ClearChat.header', 'Clear Chat History')}
        </ConfirmHeader>
        <ConfirmContent>
          {t(
            'chatbot:ClearChat.content',
            'Are you sure you want to clear chat history? You will not be able to undo this action.'
          )}
        </ConfirmContent>
        <ConfirmButtons>
          <Button
            btnType={ButtonType.SECONDARY_TEXT}
            onClick={() => setOpen(false)}>
            {t('generic:Cancel', 'Cancel')}
          </Button>
          <Button
            margin="0"
            btnType={ButtonType.PRIMARY_DESTRUCTIVE}
            onClick={onDelete}>
            {t('generic:Delete', 'Delete')}
          </Button>
        </ConfirmButtons>
      </>
    )
  }

  return (
    <Popover
      padding="20px"
      width="350px"
      open={isOpen}
      onOpenChange={setOpen}
      content={renderContent()}>
      <Button
        btnType={ButtonType.PRIMARY_TEXT}
        iconName="TRASH"
        margin="0"
        mini
        onClick={() => setOpen(true)}>
        Clear Chat
      </Button>
    </Popover>
  )
}
