import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import { Slideout, Icon, LoadingIcon } from '@junglescout/edna'
import { BetaTag } from 'ui_elements/BetaTag/BetaTag'

import { ROUTER_STATE_KEYS } from 'constants/router_state'
import { useChatHistory, useChat } from 'hooks/chat'
import { useRouterState } from 'hooks/router_state'
import { setChatbotState, toggleChatbot } from 'actions/chatbot'

import { ChatbotSlideoutTrigger } from './ChatbotSlideoutTrigger'
import { ClearChatButton } from './ClearChatButton'
import { ChatBot } from './ChatBot'
import { useTranslation } from 'react-i18next'

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${props => props.theme.colors.primary};
`
const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`
const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const LoadingIconWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const ChatBotSlideout = () => {
  const { t } = useTranslation('chatbot')
  const dispatch = useDispatch()

  const openByRouter = useRouterState(ROUTER_STATE_KEYS.openAIAssistChat)

  const isOpen = useSelector(state => state.chatbot.isOpen)
  const createReportParams = useSelector(
    state => state.chatbot.createReportParams
  )

  const [showInitialSuggestions, setShowInitialSuggestions] = useState(false)

  const { questions, isLoadingHistory } = useChatHistory()
  const {
    sendQuestion,
    createAnswer,
    createReport,
    streamingString,
    isStreaming,
    error
  } = useChat()

  useEffect(() => {
    // Always show initial suggestions if there are less than 3 questions
    if (questions.length < 3 && !isLoadingHistory) {
      setShowInitialSuggestions(true)
    }
  }, [questions, isLoadingHistory])

  useEffect(() => {
    if (createReportParams) {
      createReport(createReportParams)
      dispatch(setChatbotState({ createReportParams: undefined }))
    }
  }, [dispatch, createReportParams, createReport])

  useEffect(() => {
    if (openByRouter) {
      dispatch(toggleChatbot())
    }
  }, [openByRouter, dispatch])

  const onOpenChange = () => dispatch(toggleChatbot())

  const renderTitle = () => {
    return (
      <StyledHeader>
        <Title>
          <Icon name="AI_ASSIST" />
          {t('chatbot:common.cta', 'AI Assist Chat')}
          <BetaTag margin="0 0 0 4px" />
        </Title>
        {questions.length > 0 && <ClearChatButton />}
      </StyledHeader>
    )
  }

  return (
    <Slideout
      title={renderTitle()}
      content={
        <Content>
          {isLoadingHistory ? (
            <LoadingIconWrapper>
              <LoadingIcon size="50px" />
            </LoadingIconWrapper>
          ) : (
            <ChatBot
              questions={questions}
              createAnswer={createAnswer}
              sendQuestion={sendQuestion}
              streamingString={streamingString}
              isStreaming={isStreaming}
              error={error}
              showInitialSuggestions={showInitialSuggestions}
            />
          )}
        </Content>
      }
      open={isOpen}
      onOpenChange={onOpenChange}>
      <span>
        <ChatbotSlideoutTrigger />
      </span>
    </Slideout>
  )
}

export { ChatBotSlideout }
