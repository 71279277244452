import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button, ButtonType, Tooltip, TextInput } from '@junglescout/edna'
import { TEXT_STYLES } from 'constants/text_styles'

import { DateRangeInput } from './inputs/DateRangeInput'
import { MarketplaceInput } from './inputs/MarketplaceInput'
import { getMissingParamsTypes, MISSING_PARAMS_TYPES } from 'helpers/chatbot'

const Container = styled.div`
  width: 100%;
`

const Item = styled.div`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${props =>
    props.disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed;
  `}
`

const Label = styled.label`
  margin-bottom: 4px;
  ${TEXT_STYLES.H5Black};
`

const AnswerMissingParams = ({
  currentParams,
  missingParams,
  disabled,
  sendRequest
}) => {
  const [params, setParams] = useState(currentParams)
  const missingParamsType = getMissingParamsTypes(missingParams)

  const isValid = !missingParams?.find(key => !params[key])

  const inputs = []
  let allowAutoSubmit = true

  const setValues = values => {
    setParams(values)

    // Auto submit
    if (allowAutoSubmit && !disabled) {
      sendRequest(values)
    }
  }

  missingParamsType.forEach(type => {
    if (type === MISSING_PARAMS_TYPES.COMPARISON_DATE_RANGE) {
      inputs.push({
        label: `${type}:`,
        children: (
          <DateRangeInput values={params} setValues={setValues} isComparison />
        )
      })
    }

    if (type === MISSING_PARAMS_TYPES.DATE_RANGE) {
      inputs.push({
        label: `${type}:`,
        children: <DateRangeInput values={params} setValues={setValues} />
      })
    }

    if (type === MISSING_PARAMS_TYPES.MARKETPLACE) {
      inputs.push({
        label: type,
        children: <MarketplaceInput values={params} setValues={setValues} />
      })
    }

    if (type === MISSING_PARAMS_TYPES.SEARCH_TERMS) {
      allowAutoSubmit = false
      inputs.push({
        label: `${type}:`,
        children: (
          <TextInput
            value={params.search_terms}
            disabled={disabled}
            onChange={e =>
              setValues({ ...params, search_terms: e.target?.value })
            }
          />
        )
      })
    }
  })

  if (inputs.length > 1) {
    allowAutoSubmit = false
  }

  const tooltip = isValid
    ? 'Send the missing data to the AI Chat to continue with your question'
    : 'You need to fill the missing data to continue with your question'

  return inputs.length ? (
    <Container>
      {inputs.map((input, i) => (
        <Item key={String(i)} disabled={disabled}>
          <Label>{input.label}</Label>
          {input.children}
        </Item>
      ))}
      {!disabled && !allowAutoSubmit && (
        <Item>
          <Tooltip content={tooltip} size="medium">
            <span>
              <Button
                btnType={ButtonType.PRIMARY}
                onClick={() => sendRequest(params)}
                disabled={!isValid}
                width="auto"
                margin="0">
                Continue with the question
              </Button>
            </span>
          </Tooltip>
        </Item>
      )}
    </Container>
  ) : null
}

AnswerMissingParams.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentParams: PropTypes.object,
  missingParams: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  sendRequest: PropTypes.func.isRequired
}

AnswerMissingParams.defaultProps = {
  currentParams: {},
  disabled: false
}

export { AnswerMissingParams }
