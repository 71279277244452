import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { QuestionPropTypes } from './questions/types'
import { TEXT_STYLES } from 'constants/text_styles'
import { CHAT_SPACING } from 'constants/chatbot'

import { ChatBotFooter } from './ChatBotFooter'
import { Question } from './questions/Question'
import { QuestionEmpty } from './questions/QuestionEmpty'

const CHAT_BOTTOM_ID = 'chatBottom'

const Container = styled.div`
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: auto;
`

const ChatContainer = styled.div`
  overflow: auto;
  margin-bottom: ${CHAT_SPACING}px;
  padding: 0 ${CHAT_SPACING}px;
  padding-top: ${CHAT_SPACING}px;
`

const ErrorLabel = styled.label`
  width: 100%;
  ${TEXT_STYLES.H5Light};
  margin-top: ${CHAT_SPACING}px;
`

const ChatBot = ({
  questions,
  createAnswer,
  sendQuestion,
  streamingString,
  isStreaming,
  error,
  showInitialSuggestions
}) => {
  useEffect(() => {
    setTimeout(() => {
      const chatBottom = document.getElementById(CHAT_BOTTOM_ID)
      chatBottom?.scrollIntoView({ behavior: 'auto' })
    }, 100)
  }, [questions, streamingString, isStreaming])

  return (
    <Container>
      <ChatContainer>
        {(isEmpty(questions) || showInitialSuggestions) && (
          <QuestionEmpty sendQuestion={sendQuestion} />
        )}

        {questions.map((question, i) => {
          const isLastOne = i === questions.length - 1
          return (
            <Question
              key={question.id || String(i)}
              question={question}
              createAnswer={createAnswer}
              streamingString={isLastOne ? streamingString : undefined}
              isStreaming={isLastOne ? isStreaming : undefined}
              isLastOne={isLastOne}
            />
          )
        })}

        {error && <ErrorLabel>{error}</ErrorLabel>}

        <div id={CHAT_BOTTOM_ID} />
      </ChatContainer>
      <ChatBotFooter sendQuestion={sendQuestion} disabled={isStreaming} />
    </Container>
  )
}

ChatBot.propTypes = {
  questions: PropTypes.arrayOf(QuestionPropTypes).isRequired,
  createAnswer: PropTypes.func.isRequired,
  sendQuestion: PropTypes.func.isRequired,
  streamingString: PropTypes.string,
  isStreaming: PropTypes.bool,
  error: PropTypes.string,
  showInitialSuggestions: PropTypes.bool
}

ChatBot.defaultProps = {
  streamingString: undefined,
  isStreaming: false,
  error: undefined,
  showInitialSuggestions: false
}

export { ChatBot }
