import React from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAnswerUpdate } from 'hooks/chat'
import { sendSafeSegmentTrackEvent } from 'services/segment'

import { TEXT_STYLES } from 'constants/text_styles'
import { ClickableIcon } from '@junglescout/edna'

import { AnswerPropTypes, QuestionPropTypes } from './types'

const Container = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const EvaluationText = styled.label`
  ${TEXT_STYLES.H5Light};
  color: ${props => props.theme.colors.grey600};
  margin-right: 8px;
  padding-top: 2px;
`

const POSITIVE_FEEDBACK = 1
const NEGATIVE_FEEDBACK = 0

const AnswerFeedback = ({ question, answer }) => {
  const { t } = useTranslation('chatbot')
  const { colors } = useTheme()

  const { updateAnswer } = useAnswerUpdate()
  const { id, score, type } = answer

  const sendFeedback = feedback => {
    // Overwrite the answer's score with the feedback
    const answers = question.answers.map(a =>
      a.id === answer.id ? { ...a, score: feedback } : a
    )

    updateAnswer(
      { id, question_id: question.id, score: feedback },
      { ...question, answers }
    )

    sendSafeSegmentTrackEvent('Evaluation Submitted', {
      contentIsGood: feedback === POSITIVE_FEEDBACK,
      content: 'Chatbot Response',
      input: question.text,
      output: answer.text,
      langsmithId: id
    })
  }

  const isFeedbackProvided =
    score === NEGATIVE_FEEDBACK || score === POSITIVE_FEEDBACK

  const getText = () => {
    if (!isFeedbackProvided && type === 'report') {
      return t(
        'chatbot:AnswerFeedback.feedbackRequestReport',
        'Did you find this report useful:'
      )
    }

    return isFeedbackProvided
      ? t('chatbot:AnswerFeedback.feedbackProvided', 'Thanks for your feedback')
      : t('chatbot:AnswerFeedback.feedbackRequest', 'Was this helpful?')
  }

  return id ? (
    <Container>
      <EvaluationText>{getText()}</EvaluationText>

      {(!isFeedbackProvided || score === POSITIVE_FEEDBACK) && (
        <ClickableIcon
          name="THUMBS_UP"
          height="14px"
          width="14px"
          padding="4px"
          color={colors.grey600}
          disabled={isFeedbackProvided}
          onClick={() => sendFeedback(POSITIVE_FEEDBACK)}
        />
      )}

      {(!isFeedbackProvided || score === 0) && (
        <ClickableIcon
          name="THUMBS_DOWN"
          height="14px"
          width="14px"
          padding="4px"
          color={colors.grey600}
          disabled={isFeedbackProvided}
          onClick={() => sendFeedback(NEGATIVE_FEEDBACK)}
        />
      )}
    </Container>
  ) : null
}

AnswerFeedback.propTypes = {
  question: QuestionPropTypes.isRequired,
  answer: AnswerPropTypes.isRequired
}

export { AnswerFeedback }
