import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { TEXT_STYLES } from 'constants/text_styles'
import { Icon } from '@junglescout/edna'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 18px;
  border: 1px solid ${props => props.theme.colors.primary};
  cursor: pointer;
  ${TEXT_STYLES.ButtonOrange};
  &:hover {
    background: ${props => props.theme.colors.primary100};
  }
`

const ChatButton = ({ className, icon, onClick, children }) => (
  <Container className={className} onClick={onClick}>
    {icon && <Icon name={icon} />}
    {children}
  </Container>
)

ChatButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired
}

ChatButton.defaultProps = {
  className: undefined,
  icon: undefined,
  onClick: undefined
}

export { ChatButton }
